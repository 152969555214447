import AOS from 'aos';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'swiper/css';

import 'aos/dist/aos.css';
import './src/styles/global.css';

AOS.init({
  once: true,
  disable: function () {
    var maxWidth = 768;
    return window.innerWidth < maxWidth;
  },
});
